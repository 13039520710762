import { default as _91id_93OotpjLgtg7Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/connection-drivers/[id].vue?macro=true";
import { default as indexXdQy3Vum37Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/connection-drivers/index.vue?macro=true";
import { default as index9q7dUolW5hMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/index.vue?macro=true";
import { default as indexLVsM9aomQ0Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accessesOWAtmTDMWmMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storescVUTxGZDc6Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsOhMyPFTSSUMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93EWiOkBK72JMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id].vue?macro=true";
import { default as indexyZNMPcwDLPMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/index.vue?macro=true";
import { default as _91id_93FhoSuuBbR5Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/users/[id].vue?macro=true";
import { default as indexMwABIw59TqMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/index.vue")
  },
  {
    name: _91id_93EWiOkBK72JMeta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20241213122136/pages/users/index.vue")
  }
]